<template>

  <CRow>
    <CCol col="12" lg="12">
      <CCard>
        <CCardHeader>
          <CCol>

            <strong v-if="Object.keys(activePlan).length > 0 && activePlan.exist===true">Success Transaction
              Detail</strong>
            <strong v-if="Object.keys(activePlan).length > 0 && activePlan.exist===false">Pending Transaction
              Detail</strong>

            <back-to-list></back-to-list>
          </CCol>
        </CCardHeader>
        <CCardBody>
          <CAlert
              :color="messageColor"
              closeButton
              :show.sync="dismissCountDown"
              fade
          >{{ err_msg }}
          </CAlert>
          <div v-if="Object.keys(activePlan).length > 0">
            <div v-if="activePlan.exist===true">
              <CCol md="12">

                <CRow>
                  <CCol md="12" class="text-right" v-show="activePlan.invoiceUrl">
                    <a color="primary" class="btn btn-primary" size="sm" _target="blank" :href="activePlan.invoiceUrl">View
                      Invoice</a>
                  </CCol>
                </CRow>
                <CRow>


                  <CCol md="3" class="text-left">
                    <ProfilerDetail :username="activePlan.company" :type="activePlan.type"
                                    :profilerId="activePlan.userId"></ProfilerDetail>
                  </CCol>
                  <CCol md="3" class="text-left">
                    Invoice No: {{ activePlan.invoiceNo }}
                  </CCol>
                  <CCol md="3" class="text-left">
                    Plan Name: {{ activePlan.name }}
                  </CCol>
                  <CCol md="3" class="text-left">
                    Plan Period: {{ activePlan.durationType }}
                  </CCol>
                  <CCol md="3" class="text-left">
                    Duration: {{ activePlan.duration }}
                    {{
                      activePlan.durationType === "Monthly"
                          ? "Month"
                          : activePlan.durationType === "Yearly"
                              ? "Year"
                              : "Days"
                    }}
                  </CCol>
                  <CCol md="3" class="text-left">
                    Purchase Date: {{ getDateWithFormat(activePlan.date) }}
                  </CCol>
                  <CCol md="3" class="text-left">
                    Start Date: {{ getDateWithFormat(activePlan.startDate) }}
                  </CCol>
                  <CCol md="3" class="text-left">
                    End Date: {{ getDateWithFormat(activePlan.endDate) }}
                  </CCol>
                  <CCol md="3" class="text-left">
                    Amount: {{ activePlan.amount }}
                  </CCol>
                  <CCol md="3" class="text-left">
                    Discount: {{ activePlan.discountValue }}
                  </CCol>
                  <CCol md="3" class="text-left">
                    Total Amount: {{ activePlan.totalAmount }}
                  </CCol>
                  <CCol md="3" class="text-left">
                    Profile Status :
                    <CBadge :color="statusBudget(activePlan.statusMessage)">{{ activePlan.statusMessage }}</CBadge>
                  </CCol>
                  <CCol md="3" class="text-left">
                    RazorPay Payment Status :
                    <CBadge :color="statusBudget(activePlan.razorPayStatus)">{{ activePlan.razorPayStatus }}</CBadge>
                  </CCol>
                  <CCol md="3" class="text-left">
                    Order Status :
                    <CBadge :color="statusBudget(activePlan.orderStatus)">{{ activePlan.orderStatus }}</CBadge>
                  </CCol>
                </CRow>

              </CCol>
            </div>
            <div v-if="activePlan.exist===false">
              <CCol md="12">
                <CRow>
                  <CCol md="3" class="text-left">
                    Plan : {{ activePlan.planName }}
                  </CCol>
                  <CCol md="3" class="text-left">
                    Purchase Type: {{ activePlan.purchase }}
                  </CCol>
                  <CCol md="3" class="text-left">
                    Future Plan: {{ activePlan.isFuture }}
                  </CCol>
                  <CCol md="3" class="text-left">
                    Plan Amount: {{ activePlan.totalAmount }}
                  </CCol>
                  <CCol md="3" class="text-left">
                    Duration: {{ activePlan.duration }}
                    {{
                      activePlan.durationType === "Monthly"
                          ? "Month"
                          : activePlan.durationType === "Yearly"
                              ? "Year"
                              : "Days"
                    }}
                  </CCol>
                  <CCol md="3" class="text-left">
                    Purchase Date: {{ dateTimeUTCtoLocalFormat(activePlan.createdAt) }}
                  </CCol>
                  <CCol md="3" class="text-left" v-show="activePlan.discountValue">
                    Discount Value: {{ activePlan.discountValue }}
                  </CCol>
                  <CCol md="3" class="text-left" v-show="activePlan.discountCode">
                    Discount Code: {{ activePlan.discountCode }}
                  </CCol>
                  <CCol md="3" class="text-left" v-if="activePlan.noOfUsers>0">
                    No Of Users: {{ activePlan.noOfUsers }}
                  </CCol>
                  <CCol md="3" class="text-left">
                    RazorPay Payment Status :
                    <CBadge :color="statusBudget(activePlan.razorPayStatus)">{{ activePlan.razorPayStatus }}</CBadge>
                  </CCol>
                  <CCol md="3" class="text-left">
                    Order Status :
                    <CBadge :color="statusBudget(activePlan.orderStatus)">{{ activePlan.orderStatus }}</CBadge>
                  </CCol>
                </CRow>
                <br>

                <CRow>
                  <CCol sm="12" align="left">
                    <CButton
                        color="primary"
                        :disabled="submitted"
                        v-c-tooltip="$lang.post.crud.post_status"
                        v-on:click="placeOrder(self.activePlan.orderId)"
                        v-if="activePlan.razorPayStatus==='Paid' && activePlan.orderStatus==='Pending'"
                    >
                      Place Order
                    </CButton>
                  </CCol>
                </CRow>
              </CCol>
            </div>
          </div>
        </CCardBody>
      </CCard>
    </CCol>
  </CRow>


</template>

<script>
import store from "/src/store/store.js";
import {transactions} from "/src/store/url.js";
import {Mixin} from "/src/mixins/index.js";
import BackToList from "../backToList.vue";
import ProfilerDetail from "/src/views/ProfilerDetail.vue";

export default {
  name: "TransactionView",
  mixins: [Mixin],
  components: {
    BackToList,
    ProfilerDetail,
  },
  data() {
    return {
      self: this,
      submitted: false, //Enabled Submit Button
      orderId: '',
      module: transactions,
      activePlan: {},
      err_msg: "",
      messageColor: "success",
      showAlert: false,
      dismissCountDown: 0,
      dismissCountDownS: 0,
      newActivePlan: {},
    };
  },
  mounted() {
    let self = this;
    // this.loadData();
    self.dismissCountDown = 0;
    self.dismissCountDownS = 0;
  },
  created() {
    let self = this;
    const id = self.$route.params.id;
    store.commit("showLoader", false); // Loader Off
    axios.get("transactions/view/" + id).then((response) => {
      if (response.status === 200) {
        self.activePlan = response.data.data;
        self.orderId = self.activePlan.orderId;
      }
    });
  },
  methods: {

    placeOrder(id) {
      let self = this;
      const postData = {
        orderId: id,
      };
      self.submitted = true; //Disable Button
      axios
          .post(this.updateUrlweb(this.module, id), postData)
          .then(function (response) {
            if (response.data.status === 200) {
              self.err_msg = response.data.message;
              self.messageColor = "success";
              self.dismissCountDown = 10;
              setTimeout(() => {
                window.location.reload();
              }, 1500);
            } else {
              self.err_msg = response.data.message;
              self.messageColor = "danger";
              self.dismissCountDown = 10;
              self.submitted = false; //Enable Button
            }
          })
          .catch(function (error) {
            let data;
            if (error.response.data.error) {
              data = error.response.data.error.toString();
            } else {
              data = error.response.data.message;
            }
            self.err_msg = data;
            self.dismissCountDown = 10;
            self.submitted = false; //Enable Button
          });
    }


  },
};
</script>
